import React, { FC, ReactNode } from "react";
import { Auth0Provider } from "@auth0/auth0-react";

import useTenantId from "hooks/useTenantId";

const tenantIdToAuth0Connection = new Map([
  [1, "top-notch-fitness"],
  [2, "spinnvill"],
  [3, "spinnvill"],
]);

interface IProps {
  children: ReactNode;
}

const RedirectingAuth0Provider: FC<IProps> = ({ children }) => {
  const tenantId = useTenantId();

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
        redirect_uri: window.location.origin,
        connection: tenantIdToAuth0Connection.get(Number(tenantId)),
      }}
      cacheLocation={
        ["development", "test"].includes(process.env.NODE_ENV)
          ? "localstorage"
          : "memory"
      }
    >
      {children}
    </Auth0Provider>
  );
};

export default RedirectingAuth0Provider;
