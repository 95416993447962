import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { Grid } from "@mui/material";
import {
  AccountBox,
  AccountBoxOutlined,
  Logout,
  ManageAccountsOutlined,
  SwitchAccountOutlined,
} from "@mui/icons-material";

import { DATE_UI_FORMAT, Member } from "@tnt/common";
import { breakpointsValues, makeStyles } from "theme";
import Scene from "components/Scene";
import useMembersOfUser from "hooks/useMembersOfUser";
import useMemberId from "hooks/useMemberId";
import MenuButton from "components/MenuButton";
import useTenantId from "hooks/useTenantId";

const useStyles = makeStyles()(({ tenantId, spacing, windowInnerWidth }) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    paddingTop: spacing(3),
    paddingRight: windowInnerWidth <= breakpointsValues.sm ? spacing(4) : 0,
    paddingBottom: spacing(4),
    paddingLeft: windowInnerWidth <= breakpointsValues.sm ? spacing(4) : 0,
    width: "100%",
    maxWidth: 576,
  },
  relationshipGroupTitle: {
    width: "100%",
    textAlign: "center",
    fontFamily: "arial",
    fontSize: "1.4rem",
    color: tenantId === 1 ? "black" : "white",
    marginBottom: `-${spacing(3)}`,
    userSelect: "none",
  },
  hr: {
    height: 2,
    backgroundColor: "lightgrey",
    marginTop: spacing(4),
    marginBottom: spacing(4),
    marginRight: windowInnerWidth > breakpointsValues.sm ? 0 : `-${spacing(4)}`,
    marginLeft: windowInnerWidth > breakpointsValues.sm ? 0 : `-${spacing(4)}`,
  },
  logout: {
    padding: spacing(2),
    width: "auto",
  },
}));

const MemberSelection = () => {
  const { classes } = useStyles();

  const { user, logout } = useAuth0();

  const tenantId = useTenantId();

  const navigate = useNavigate();

  const [members, setMembers] = useState<Array<Member> | undefined>();

  const membersOfUser = useMembersOfUser();
  const [, setMemberId] = useMemberId();

  useEffect(() => {
    if (membersOfUser) {
      switch (membersOfUser.length) {
        case 0:
          void logout({ logoutParams: { returnTo: window.location.origin } });
          break;

        case 1:
          setMemberId(membersOfUser[0].id!);
          break;

        default:
          setMembers(membersOfUser.sort((a, b) => (!a.id && b.id ? 1 : -1)));
      }
    }
  }, [logout, membersOfUser, setMemberId]);

  if (!members) return null;

  const memberRelations = members.filter(
    (m) => m.customer.auth0UserId === user?.sub
  );
  const guardianRelations = members.filter(
    (m) => m.guardian?.auth0UserId === user?.sub
  );

  const greenColor = tenantId === 1 ? "rgb(0,100,0,0.75)" : "rgb(75,150,75)";

  return (
    <Scene
      title="Velg bruker"
      icon={
        <SwitchAccountOutlined fontSize="large" style={{ color: greenColor }} />
      }
      showClose={false}
      usePadding={false}
    >
      <div className={classes.root}>
        <Grid container spacing={4}>
          {memberRelations.length > 0 && (
            <Grid
              item
              container
              xs={12}
              sm={guardianRelations.length > 0 ? 6 : 12}
              spacing={4}
              direction="column"
            >
              <Grid item className={classes.relationshipGroupTitle}>
                Medlemsforhold
              </Grid>

              <Grid item container spacing={4}>
                {memberRelations.map((m) => (
                  <Grid
                    item
                    xs={12}
                    sm={guardianRelations.length > 0 ? 12 : 6}
                    key={m.id}
                  >
                    <MenuButton
                      icon={
                        <AccountBox
                          fontSize="large"
                          style={{
                            color: tenantId === 1 ? greenColor : "rgb(0,75,0)",
                          }}
                        />
                      }
                      title={`${m.customer.firstName} ${m.customer.lastName}`}
                      subtitle={
                        memberRelations.length > 1
                          ? moment(m.customer.created).format(DATE_UI_FORMAT)
                          : undefined
                      }
                      onClick={() => setMemberId(m.id!)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}

          {guardianRelations.length > 0 && (
            <Grid
              item
              container
              xs={12}
              sm={memberRelations.length > 0 ? 6 : 12}
              spacing={4}
              direction="column"
            >
              <Grid item className={classes.relationshipGroupTitle}>
                Vergeforhold
              </Grid>

              {guardianRelations.map((m) => (
                <Grid item key={m.id}>
                  <MenuButton
                    icon={
                      <AccountBoxOutlined
                        fontSize="large"
                        style={{
                          color: tenantId === 1 ? greenColor : "rgb(0,75,0)",
                        }}
                      />
                    }
                    title={`${m.customer.firstName} ${m.customer.lastName}`}
                    onClick={() => setMemberId(m.id!)}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>

        <div>
          <div className={classes.hr} />

          <Grid container spacing={4}>
            <Grid item xs={6}>
              <MenuButton
                icon={
                  <ManageAccountsOutlined
                    fontSize="large"
                    style={{ color: "#666666" }}
                  />
                }
                title="Innstillinger"
                onClick={() => navigate("/settings")}
                lessPadding
              />
            </Grid>

            <Grid item xs={6}>
              <MenuButton
                icon={<Logout fontSize="large" style={{ color: "#CC4040" }} />}
                title="Logg ut"
                onClick={() =>
                  logout({ logoutParams: { returnTo: window.location.origin } })
                }
                lessPadding
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </Scene>
  );
};

export default MemberSelection;
